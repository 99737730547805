import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function FormItem({
  title,
  control,
  description,
}: {
  title: React.ReactNode
  control: React.ReactNode
  description?: React.ReactNode
}) {
  return (
    <div>
      <MText variant="subtitle" marginBottom={spacing.M}>
        {title}
      </MText>
      {control}
      <MText variant="body" marginBottom={spacing.XS}>
        {description}
      </MText>
    </div>
  )
}
