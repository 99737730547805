import { Switch } from "@material-ui/core"
import { FormikProps } from "formik"

import { TCreateClientForm } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientDialog"
import { FormItem } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseFormItem"
import { Divider } from "src/ui/Divider/Divider"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { MultipleValuesField } from "src/ui/MultipleValuesField/MultipleValuesField"
import { spacing } from "src/ui/spacing"

export function ParadiseClientOAuthSettings({
  form,
}: {
  form: FormikProps<TCreateClientForm>
}) {
  return (
    <>
      <div>
        <MText variant="heading3">Authorization Code Grant</MText>
        <Divider $margin={spacing.S} />
        <MText variant="body">
          The Authorization Code grant type is used by confidential and public
          clients to exchange an authorization code for an access token.
        </MText>
        <ExternalLink href="https://oauth.net/2/grant-types/authorization-code/">
          More info
        </ExternalLink>
      </div>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.authorization_code_grant.enabled}
            name="authorization_code_grant.enabled"
            onChange={form.handleChange}
          />
        }
      />

      <FormItem
        title="Redirect URIs"
        control={
          <MultipleValuesField
            inputType="url"
            textFields={form.values.authorization_code_grant.redirect_uris}
            onChange={(uris) => {
              form.setValues((prev) => ({
                ...prev,
                authorization_code_grant: {
                  ...prev.authorization_code_grant,
                  redirect_uris: uris,
                },
              }))
            }}
            buttonLabel="Add another redirect URI"
          />
        }
      />

      <FormItem
        title="Allowed origins"
        control={
          <MultipleValuesField
            textFields={form.values.authorization_code_grant.allowed_origins}
            onChange={(origins) => {
              form.setValues((prev) => ({
                ...prev,
                authorization_code_grant: {
                  ...prev.authorization_code_grant,
                  allowed_origins: origins,
                },
              }))
            }}
            buttonLabel="Add another allowed origin"
            inputType="url"
          />
        }
      />
      <div>
        <MText variant="heading3">Client Credentials Grant</MText>
        <Divider $margin={spacing.S} />
        <MText variant="body">
          The Client Credentials grant type is used by clients to obtain an
          access token outside of the context of a user.
        </MText>
        <ExternalLink href="https://oauth.net/2/grant-types/client-credentials/">
          More info
        </ExternalLink>
      </div>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.client_credentials_grant.enabled}
            name="client_credentials_grant.enabled"
            onChange={form.handleChange}
          />
        }
      />
      <div>
        <MText variant="heading3">Resource Owner Password Grant</MText>
        <Divider $margin={spacing.S} />
        <MText variant="body">
          The Password grant type is a legacy way to exchange a user's
          credentials for an access token. Because the client application has to
          collect the user's password and send it to the authorization server,
          it is not recommended that this grant be used at all anymore.
        </MText>
        <ExternalLink href="https://oauth.net/2/grant-types/password/">
          More info
        </ExternalLink>
      </div>
      <FormItem
        title="Enabled"
        control={
          <Switch
            checked={form.values.owner_password_grant.enabled}
            name="owner_password_grant.enabled"
            onChange={form.handleChange}
            disabled={
              !form.values.restricted_to_owner && !form.values.first_party
            }
          />
        }
        description="Note: If the client is not a 1:st party client and it is not restricted to owner this must be disabled"
      />
    </>
  )
}
