import styled from "styled-components"

import { Select } from "@material-ui/core"
import { useFormik } from "formik"

import { ParadiseClientOAuthSettings } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseClientOAuthSettings"
import { ParadiseClientSettings } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseClientSettings"
import { ParadiseCreateClientOwner } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseCreateClientOwner"
import { FormItem } from "src/components/Paradise/ParadiseClients/ParadiseCreateClientDialog/ParadiseFormItem"
import { usePostApiClient } from "src/data/apiClients/apiClientQueries"
import { OwnerType } from "src/data/integrations/types/integrationTypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MDialog } from "src/ui/Dialog/MDialog"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { InfoBox } from "src/ui/InfoBox/InfoBox"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export type TCreateClientForm = {
  name: string
  description: string
  logo_url: string
  owner: {
    type: OwnerType
    id: string
    email: string
  }
  enabled: boolean
  // Client settings
  restricted_to_owner: boolean
  roles: string[]
  first_party: boolean
  can_access_third_party_data: boolean
  minimum_api_version: number
  user_required_role: string
  // OAuth settings
  authorization_code_grant: {
    enabled: boolean
    redirect_uris: string[]
    allowed_origins: string[]
    require_pkce: boolean
  }
  client_credentials_grant: {
    enabled: boolean
  }
  owner_password_grant: {
    enabled: boolean
  }
}

const INITIAL_VALUE: TCreateClientForm = {
  name: "",
  description: "",
  logo_url: "",
  owner: {
    type: OwnerType.USER,
    id: "",
    email: "",
  },
  enabled: true,
  // Client settings
  restricted_to_owner: true,
  roles: [],
  first_party: false,
  can_access_third_party_data: false,
  minimum_api_version: 8,
  user_required_role: "",
  // OAuth settings
  authorization_code_grant: {
    enabled: true,
    redirect_uris: [],
    allowed_origins: [],
    require_pkce: false,
  },
  client_credentials_grant: {
    enabled: true,
  },
  owner_password_grant: {
    enabled: false,
  },
}

const CREATE_CLIENT_FORM_ID = "create_client_form"

export function ParadiseCreateClientDialog({
  open,
  onClose,
  fixedOwnerType,
  fixedOwnerId,
}: {
  open: boolean
  onClose: () => void
  fixedOwnerType?: OwnerType
  fixedOwnerId?: string
}) {
  const router = useRouter()

  const postAPIClient = usePostApiClient()

  const form = useFormik<typeof INITIAL_VALUE>({
    initialValues: {
      ...INITIAL_VALUE,
      owner: {
        id: fixedOwnerId || "",
        type: fixedOwnerType || OwnerType.USER,
        email: fixedOwnerId || "",
      },
    },
    onSubmit: (values) => {
      postAPIClient.mutate(
        {
          ...values,
          owner: {
            type: values.owner.type,
            id: values.owner.id,
          },
        },
        {
          onSuccess: (data) => {
            router.navigate(Routes.ParadiseClient.location(data.client_id))
          },
          onError: (_error) => {
            form.setStatus("error")
          },
        }
      )
    },
  })

  return (
    <MDialog
      title="Create client"
      open={open}
      onClose={onClose}
      formId={CREATE_CLIENT_FORM_ID}
      confirmLabel="Create"
    >
      <form
        id={CREATE_CLIENT_FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          form.handleSubmit()
        }}
      >
        <FieldsWrapper>
          {form.status === "error" && (
            <InfoBox type="warning" title="An error occured" />
          )}
          <FormItem
            title="Name"
            control={
              <MTextField
                value={form.values.name}
                name="name"
                onChange={(_, e) => form.handleChange(e)}
                required
              />
            }
          />
          <FormItem
            title="Description"
            control={
              <MTextField
                value={form.values.description}
                name="description"
                onChange={(_, e) => form.handleChange(e)}
              />
            }
          />
          <FormItem
            title="Logo URL"
            control={
              <MTextField
                value={form.values.logo_url}
                name="logo_url"
                onChange={(_, e) => form.handleChange(e)}
              />
            }
          />
          <FormItem
            title="Owner type"
            control={
              <Select
                native
                value={form.values.owner.type}
                name="owner.type"
                onChange={(e) => {
                  form.setValues((prev) => ({
                    ...prev,
                    owner: {
                      ...prev.owner,
                      type: e.target.value as OwnerType,
                      id: "",
                      email: "",
                    },
                  }))
                  form.setValues((prev) => ({
                    ...prev,
                    owner: {
                      ...prev.owner,
                      id: "",
                    },
                  }))
                  form.setValues((prev) => ({
                    ...prev,
                    owner: {
                      ...prev.owner,
                      email: "",
                    },
                  }))
                }}
                fullWidth
                disabled={!!fixedOwnerType}
              >
                <option value={OwnerType.USER}>User</option>
                <option value={OwnerType.ORGANIZATION}>Organization</option>
              </Select>
            }
          />
          <FormItem
            title="Owner"
            control={
              <ParadiseCreateClientOwner
                owner={form.values.owner}
                ownerType={form.values.owner.type}
                onChange={(selectedOwner) => {
                  form.setValues((prev) => ({
                    ...prev,
                    owner: {
                      ...prev.owner,
                      id: selectedOwner.id,
                    },
                  }))
                  form.setValues((prev) => ({
                    ...prev,
                    owner: {
                      ...prev.owner,
                      email: selectedOwner.email,
                    },
                  }))
                }}
                disabled={!!fixedOwnerId}
              />
            }
          />
          <ExpandableSection title="Client settings">
            <FieldsWrapper>
              <ParadiseClientSettings form={form} />
            </FieldsWrapper>
          </ExpandableSection>
          <ExpandableSection title="OAuth settings">
            <FieldsWrapper>
              <ParadiseClientOAuthSettings form={form} />
            </FieldsWrapper>
          </ExpandableSection>
        </FieldsWrapper>
      </form>
    </MDialog>
  )
}

const FieldsWrapper = styled.div`
  display: grid;
  gap: ${spacing.XL};
`
